@font-face {
  font-family: 'industry-custom';
  src: url('/fonts/industry-book.otf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'industry-custom';
  src: url('/fonts/industry-book-italic.otf');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'industry-custom';
  src: url('/fonts/industry-demi.otf');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'industry-custom';
  src: url('/fonts/industry-demi-italic.otf');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'industry-custom';
  src: url('/fonts/industry-bold.otf');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'industry-custom';
  src: url('/fonts/industry-bold-italic.otf');
  font-weight: 600;
  font-style: italic;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html,
  body {
    @apply max-w-full h-full scroll-smooth lg:scroll-pt-32 scroll-pt-20;
  }

  a,
  a:hover {
    @apply no-underline;
  }

  button {
    @apply border-none bg-transparent;
  }

  button:focus,
  input:focus,
  textarea:focus {
    @apply outline-none;
  }

  textarea {
    @apply resize-none;
  }
}

@layer components {
  .button {
    @apply bg-primary hover:bg-primaryHover transition-colors rounded py-2 px-5 text-white inline-block text-center sm:text-lg;
  }

  .button-transparent {
    @apply bg-transparent hover:bg-white hover:text-black transition-colors border border-solid border-white rounded py-2 px-5 text-white inline-block text-center sm:text-lg;
  }

  .button-outline {
    @apply bg-dark hover:bg-[#000] transition-colors border border-solid border-dark rounded py-2 px-5 text-white inline-block text-center sm:text-lg;
  }
}

@layer utilities {
  .ellipsis {
    @apply overflow-hidden text-ellipsis whitespace-nowrap;
  }

  .with-line {
    @apply font-bold inline-flex items-center;
  }

  a.with-line {
    @apply xl:hover:before:w-[80px] hover:before:w-[55px];
  }

  .with-line:before {
    content: '';
    height: 2px;
    background: currentColor;
    display: inline-block;
    margin-right: 10px;
    position: relative;

    @apply xl:w-[75px] w-[50px] transition-all;
  }
}

/* ----------START PARSECONTENT---------- */
.parsecontent h1 {
  @apply mb-5 lg:text-7xl sm:text-5xl text-4xl font-bold;
}

.parsecontent h2 {
  @apply mb-5 xl:text-6xl sm:text-5xl text-3xl font-bold;
}

.parsecontent h3 {
  @apply mb-5 text-5xl font-bold;
}

.parsecontent ul {
  padding-left: 25px;
  line-height: 2 !important;
  margin-bottom: 20px;
}

.parsecontent li:before {
  content: '';
  display: inline-block;
  width: 24px;
  height: 20px;
  background-image: url('/checkmark.svg');
  background-size: contain;
  margin-right: 10px;
  position: relative;
  top: 4px;
}

.parsecontent.ul-with-square ul {
  @apply list-none pl-16;
}

.parsecontent.ul-with-square li {
  @apply mb-10 relative sm:text-lg;
}
.parsecontent.ul-with-square li::before {
  content: '';
  width: 24px;
  height: 24px;
  background: none;
  @apply border-l-2 border-primary border-b-2 absolute left-[-61px];
}

.parsecontent.ul-with-square-small ul {
  @apply list-none pl-12;
}

.parsecontent.ul-with-square-small li {
  @apply mb-4 relative;
}
.parsecontent.ul-with-square-small li::before {
  content: '';
  width: 12px;
  height: 12px;
  background: none;
  @apply border-l-2 border-primary border-b-2 absolute left-[-30px];
}

.parsecontent table.openinghours {
  margin-left: 0;
  padding: 0;
}

.parsecontent table.openinghours td {
  padding: 5px;
}

.parsecontent strong,
.parsecontent b,
.parsecontent h1 strong,
.parsecontent h1 b,
.parsecontent h2 strong,
.parsecontent h2 b,
.parsecontent h3 strong,
.parsecontent h3 b,
.parsecontent h4 strong,
.parsecontent h4 b,
.parsecontent h5 strong,
.parsecontent h5 b {
  @apply font-bold;
}

.parsecontent h1:last-child,
.parsecontent h2:last-child,
.parsecontent h3:last-child,
.parsecontent h4:last-child,
.parsecontent h5:last-child,
.parsecontent p:last-child {
  @apply mb-0;
}

.parsecontent p {
  margin-bottom: 20px;
}

.parsecontent a {
  @apply font-bold text-primary;
}

.parsecontent a:hover {
  @apply underline;
}

.alignright {
  @apply float-right text-center;
}

.aligncenter {
  @apply my-0 mx-auto block;
}

.wp-caption {
  @apply max-w-full;
}

.wp-caption-text {
  @apply text-center text-base font-bold;
}
/* ----------END PARSECONTENT---------- */

/* ----------START FORM---------- */
.form-duuf-group {
  @apply mb-5;
}

form > .form-duuf-group:last-child {
  @apply mb-0;
}

.form-duuf-input,
.form-duuf-textarea {
  @apply w-full rounded-md border-none bg-white text-black placeholder:text-black/50 px-4 py-3;
}

.form-duuf-textarea {
  @apply pt-3 h-32;
}

.form-duuf-file-input {
  @apply file:bg-primary file:mr-4 file:rounded-full file:border-none  file:py-2 file:px-4 file:text-white file:hover:bg-primaryHover file:hover:transition-all text-white;
}

.form-duuf-file-button {
  @apply text-white;
}

.form-duuf-checkbox {
  @apply mr-4 h-4 w-4 rounded-md border-none bg-white text-black focus:border-none focus:outline-none active:border-none active:outline-none;
}

.form-duuf-select__control {
  @apply !border-none  !shadow-none py-[7px] px-2 h-[48px];
}

.form-duuf-select__menu {
  @apply rounded-lg;
}

.form-duuf-select__menu-list {
  @apply px-3 py-0;
}

.form-duuf-select__option {
  @apply my-1 cursor-pointer rounded-lg transition-all !text-dark;
}

.form-duuf-select__option:first-of-type {
  @apply mt-3;
}

.form-duuf-select__option:last-of-type {
  @apply mb-3;
}

.form-duuf-select__option:active {
  @apply !bg-dark;
}

.form-duuf-select__option--is-selected {
  @apply !bg-primary/80 text-black;
}

.form-duuf-select__option--is-focused {
  @apply !bg-dark !text-white;
}

.form-duuf-select__input {
  @apply !border-none !shadow-none !outline-none;
}

.form-duuf-select__single-value {
  @apply !top-[30px] !left-[12px] !m-0 !text-slate-800;
}

.form-duuf-select__placeholder {
  @apply !top-[24px] !left-[12px] !m-0 !text-slate-500;
}

.form-duuf-group {
  @apply relative;
}

.form-duuf-error {
  @apply text-sm text-red-500;
}

.form-duuf-label {
  @apply px-2;
}

.form-duuf-submit-disabled {
  @apply opacity-50 hover:bg-blue-500;
}

.form-duuf-submit > button {
  @apply button;
}

.form-duuf-loading {
  @apply absolute left-0 top-0 z-10 h-full w-full bg-white bg-opacity-50;
}

.form-duuf-submit {
  align-self: flex-start;
}

.form-duuf-group {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.form-duuf-error {
  margin-bottom: 2.5px;
}

.form-duuf-group-consent label {
  @apply text-white;
}
/* ----------START FORM---------- */

.text-shadow {
  text-shadow: 1px 1px 8px rgb(0 0 0 / 40%);
}

.menu-item:not(.active):hover::before {
  content: '';
  position: absolute;
  bottom: -7.5px;
  left: -15px;
  display: block;
  width: calc(100% + 30px);
  height: 2px;
  animation: bars-horizontal 0.5s ease-in-out;
  @apply bg-primary;
}

.menu-item:not(.active):hover::after {
  content: '';
  position: absolute;
  bottom: -7.5px;
  left: -15px;
  display: block;
  width: 2px;
  height: 85%;
  animation: bars-vertical 0.5s ease-in-out;
  @apply bg-primary;
}

.menu-item.active {
  @apply relative;
}
.menu-item.active::before {
  content: '';
  position: absolute;
  bottom: -7.5px;
  left: -15px;
  display: block;
  width: calc(100% + 30px);
  height: 2px;
  @apply bg-primary;
}

.menu-item.active::after {
  content: '';
  position: absolute;
  bottom: -7.5px;
  left: -15px;
  display: block;
  width: 2px;
  height: 85%;
  @apply bg-primary;
}

@keyframes bars-horizontal {
  0% {
    width: 0;
  }

  100% {
    width: (100% + 30px);
  }
}

@keyframes bars-vertical {
  0% {
    height: 0;
  }

  100% {
    height: 85%;
  }
}

.thick-shadow {
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
}

.parsecontent table {
  padding-left: 25px;
  margin-left: 50px;
}

.parsecontent table td {
  padding-top: 10px;
  padding-bottom: 10px;
}

section,
footer {
  @apply overflow-hidden;
}

#nprogress .bar {
  background: #cf9e64 !important;
  height: 3px !important;
  shadow: none !important;
}

#nprogress .spinner-icon {
  border-top-color: #cf9e64 !important;
  border-left-color: #cf9e64 !important;
}

#nprogress .peg {
  box-shadow: 0 0 10px #cf9e64, 0 0 5px #cf9e64 !important;
}

.configurator-form input,
.configurator-form textarea {
  @apply w-full rounded-md border-none bg-white text-black placeholder:text-black/50 px-4 py-3;
}

.form-duuf-file-input {
  display: none;
}

.form-duuf-file-button {
  @apply button-transparent;
}

.form-duuf-file-name {
  @apply ml-4;
}

.swiper-button-prev:after,
.swiper-button-next:after {
  @apply !text-primary !text-2xl hover:text-primaryHover;
}

.wysiwyg__ul {
  @apply list-disc pl-6;
}
.wysiwyg__ol1 {
  @apply list-decimal pl-6;
}
.wysiwyg__textBold {
  @apply font-bold;
}
.wysiwyg__textItalic {
  @apply italic;
}
.wysiwyg__textUnderline {
  @apply underline;
}
.wysiwyg__paragraph {
  @apply mb-4;
}
.wysiwyg__quote {
  @apply border-l-2 pl-4;
}
